<template>
  <div>
    <div class="row mx-0 justify-content-center my-4 my-md-5">
      <div class="col-9 col-sm-7 col-md-5">
        <Search type="text" color="outline" size="big" placeholder="Search" align="left" v-model="theSearch"/>
      </div>
    </div>
    <div class="row m-0">
      <FundraiserCard v-for="fundraiser, index in filteredFundraisers" :key="index" :fundraiser="fundraiser" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Search: defineAsyncComponent(() => import('@/components/Search.vue')),
    FundraiserCard: defineAsyncComponent(() => import('../components/FundraiserCard.vue'))
  },
  name: 'VerifiedFundraisersSection',
  data () {
    return {
      theSearch: null
    }
  },
  async mounted () {
    await this.getFundraisersCount()
  },
  computed: {
    ...mapGetters(['verifiedFundraisers']),
    filteredFundraisers () {
      let search = ''
      if (this.theSearch) {
        search = this.theSearch.toLowerCase().trim()
      }
      if (this.verifiedFundraisers && this.verifiedFundraisers.length > 0) {
        return this.verifiedFundraisers.filter(fundraiser => {
          if (search && (fundraiser.name.toLowerCase().indexOf(search) <= -1 && fundraiser.fundraiserID.toString().toLowerCase().indexOf(search) <= -1 && fundraiser.region.toLowerCase().indexOf(search) <= -1)) {
            return false
          }
          return true
        })
      } else {
        return null
      }
    }
  },
  methods: {
    ...mapActions(['getFundraisersCount'])
  }
}
</script>
